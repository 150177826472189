<template>
  <v-dialog v-model="showModalSuccess" max-width="560">
    <v-card id="modalCheckout" style="max-width: 100vw; background-color: white;">
      <a class="modal-close d-inline-flex" @click="setState(false)">
        Schließen
        <i class="fa-solid fa-xmark"/>
      </a>
      <v-card-text class="pt-10 pb-0 d-flex flex-column">
        <h1 class="mt-5 mb-5 text-center">Terminanfrage gesendet</h1>
        <div class="confirmed pe-5 d-flex justify-end">
          <v-img :src="require('@/assets/undraw_confirmed.svg')"/>
        </div>
        <h2 class="text-center">Wir melden uns bei Ihnen (werktags) innerhalb von 48 Std.</h2>
        <h4 class="text-center">
          Weiter zur
        </h4>
        <v-row class="my-4">
          <v-col cols="6" class="pl-0">
            <v-btn block class="btn-primary animation button-text" @click="$router.push('/'); setState(false);">
              <i class="fa-solid fa-house mr-2"/>Startseite
            </v-btn>
          </v-col>
          <v-col cols="6" class="pe-0">
            <v-btn block class="btn-primary animation button-text" @click="$router.push('/projects'); setState(false);">
              <i class="fa-solid fa-list mr-2"/>Projekt
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'ModalSuccess',
  data() {
    return {
      showModalSuccess: false
    }
  },
  methods: {
    setState(state) {
      this.showModalSuccess = state;
    }
  }
}
</script>