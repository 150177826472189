<template>
  <div>
    <v-dialog
        v-model="showModalAccount"
        fullscreen :scrim="false"
        transition="dialog-bottom-transition"
        style="align-items: initial;"
    >
      <v-card id="modalAccount">
        <v-toolbar class="px-3">
          <v-btn
              @click="setState(false)"
              class="btn-close"
              block
              variant="outlined"
          >
            <i class="fa-solid fa-angles-down mr-2"/>
            Schließen
            <i class="fa-solid fa-angles-down ml-2"/>
          </v-btn>
        </v-toolbar>

        <v-divider/>

        <v-container class="mb-16">
          <h1 class="text-center" style="color: white; margin-top: 114px; text-shadow: 2px 2px 2px #000;">Mein Account</h1>
          <div class="d-flex flex-column">
            <!--desktop-->
            <!--mobile-->
            <v-col>
              <v-btn block class="my-5 btn-logout shadow" @click="logout">
                <i class="fa-solid fa-arrow-right-from-bracket"/> Abmelden
              </v-btn>
              <div id="tabsAccount">
                <v-tabs
                    v-model="tabIndex"
                    center-active
                    centered="true"
                    next-icon="mdi-arrow-right-bold-box-outline"
                    prev-icon="mdi-arrow-left-bold-box-outline"
                    show-arrows
                    class=""
                >
                  <v-tab v-for="(tab, i) in tabs" :key="i" :value="i">
                    {{ tab.name }}
                  </v-tab>
                </v-tabs>
              </div>
            </v-col>

            <v-window v-model="tabIndex" class="w-100">
              <v-window-item v-for="(tab, i) in tabs" :key="i" :value="i">
                <component :is="tab.component"/>
              </v-window-item>
            </v-window>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import Events from "@/components/account/AccountEvents";
import Details from "@/components/account/AccountDetails";
import Security from "@/components/account/AccountSecurity";
import {mapGetters} from "vuex";

export default {
  name: 'ModalAccount',
  data() {
    return {
      showModalAccount: false,
      tabIndex: null,
      tabs: [
        {name: 'Angefragte Termine', component: Events},
        {name: 'Persönliche Daten', component: Details},
        {name: 'Account', component: Security}
      ]
    }
  },
  computed: {
    ...mapGetters([
      'user'
    ])
  },
  methods: {
    setState(state) {
      this.showModalAccount = state;
    },
    logout() {
      this.setState(false);
      this.$store.dispatch('logout');
      this.$store.dispatch('clearProjects');
      this.$router.push('/');
    }
  }
}
</script>