<template>
  <div>
    <v-dialog v-model="showModalResetPassword" max-width="560">
      <v-card id="modalResetPassword" style="background-color: white;">
        <a class="modal-close d-inline-flex" @click="setState(false)">
          Schließen
          <i class="fa-solid fa-xmark"/>
        </a>
        <v-card-text class="pt-10 d-flex flex-column">
          <h2>Password zurücksetzen</h2>
          <h4 class="mb-5">
            <span v-if="!jwt">Geb deine E-Mail-Adresse zur Wiederherstellung deines Passworts ein.</span>
            <span v-else>Bitte wähle ein neues Passwort und bestätige es zur Wiederherstellung</span>
          </h4>
          <v-alert
              v-if="errorResetPassword"
              class="mb-5"
              density="compact"
              type="error"
              variant="tonal"
          >
            {{ errorResetPassword }}
          </v-alert>
          <v-form
              v-model="valid"
              class="w-100"
              lazy-validation
              @submit.prevent.stop="resetPassword"
              :disabled="loadingResetPassword"
          >
            <v-row no-gutters>
              <v-col v-if="!jwt" class="p-0" cols="12">
                <v-text-field
                    v-model="email"
                    :rules="[rules.required, rules.email]"
                    autocomplete="email"
                    color="#0099ff"
                    clearable
                    density="comfortable"
                    label="E-Mail"
                    type="email"
                    variant="outlined"
                />
              </v-col>
              <template v-else>
                <v-col cols="12">
                  <v-text-field
                      v-model="newPassword"
                      :rules="[rules.required, rules.password, rules.passwordMin, rules.passwordMax]"
                      autocomplete="on"
                      color="#0099ff"
                      clearable
                      density="comfortable"
                      label="Neues Passwort"
                      type="password"
                      variant="outlined"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      v-model="confirmNewPassword"
                      :rules="[rules.required, rules.confirm]"
                      autocomplete="on"
                      color="#0099ff"
                      clearable
                      density="comfortable"
                      label="Neues Passwort bestätigen"
                      type="password"
                      variant="outlined"
                  />
                </v-col>
              </template>
              <v-col class="p-0 mb-5 d-flex justify-end align-center" cols="12">
                <v-btn
                    :disabled="loadingResetPassword"
                    :loading="loadingResetPassword"
                    class="btn-primary"
                    type="submit"
                >
                  Fortfahren
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <Toast ref="Toast"/>
  </div>
</template>

<script>
import Toast from '@/components/modal/Toast.vue';
import {mapGetters} from "vuex";

export default {
  name: 'ModalResetPassword',
  components: {
    Toast
  },
  data() {
    return {
      showModalResetPassword: false,
      valid: false,
      email: '',
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      rules: {
        required: value => !!value || 'Pflichtfeld.',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Die E-Mail ist ungültig.'
        },
        password: value => {
          const pattern = /^(?=.*[a-z])(?=.*[A-Za-z])(?=.*[0-9])(?=.*[~!@#$%^&*\-_+=|";[\]{}()<>,./?\\])/;
          return pattern.test(value) || 'Das Passwort muss Buchstaben, Ziffern und Sonderzeichen(~!@#$%^&*-_+=|";[]{}()<>,./?\\) enthalten.';
        },
        passwordMin: value => value.length >= 9 || 'Das Passwort muss mindestens 9 Zeichen lang sein.',
        passwordMax: value => value.length <= 20 || 'Das Passwort darf maximal 20 Zeichen lang sein.',
        confirm: value => (value === this.newPassword) && this.newPassword !== '' || 'Die Passwörter müssen übereinstimmen.'
      },
      jwt: this.$route.query.resetPasswordToken || null
    }
  },
  computed: {
    ...mapGetters([
      'loadingResetPassword',
      'errorResetPassword'
    ])
  },
  methods: {
    setState(state) {
      this.showModalResetPassword = state;
    },
    resetPassword() {
      if (this.valid) {
        if (!this.jwt) {
          this.$store.dispatch('requestResetPassword', {
            email: this.email
          })
              .then(response => {
                if (response) {
                  this.$refs.Toast.show({
                    color: 'success',
                    icon: 'fa-solid fa-check',
                    title: 'E-Mail wurde gesendet',
                    text: 'Bitte überprüfen sie Ihre E-Mails. Schauen Sie auch in Ihren Spam Ordner.'
                  });
                }
              });
        } else {
          this.$store.dispatch('submitResetPassword', {
            jwt: this.jwt,
            newPassword: this.newPassword
          }).then(response => {
            if (response) {
              this.$refs.Toast.show({
                color: 'success',
                icon: 'fa-solid fa-check',
                title: 'Passwort erfolgreich zurückgesetzt',
                text: 'Sie können sich nun normal anmelden.'
              });
            }
          });
        }
      }
    }
  }
}
</script>