import {mutations} from "./mutations";
import {actions} from "./actions";
import {getters} from "./getters";

const state = {
    loadingProjects: false,
    errorProjects: null,
    projects: []
}

const projectsModule = {
    state,
    mutations,
    actions,
    getters
}

export default projectsModule;