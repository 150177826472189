import * as types from "./mutation-types";
import axios from "axios";

export const actions = {
    getCategories({commit}) {
        commit([types.UPDATE_ERROR], false);
        commit(types.UPDATE_LOADING, true);
        axios.get(`/api/categories`)
            .then(response => {
                if (response.data.ok) {
                    commit(types.CREATE_CATEGORIES, response.data.categories);
                } else {
                    commit([types.UPDATE_ERROR], response.data.error);
                }
                commit(types.UPDATE_LOADING, false);
            })
            .catch(() => commit(types.UPDATE_LOADING, false));
    },
}