import * as types from "./mutation-types";

export const mutations = {
    [types.UPDATE_LOADING_PROJECTS](state, payload) {
        state.loadingProjects = payload;
    },
    [types.UPDATE_ERROR_PROJECTS](state, payload) {
        state.errorProjects = payload;
    },
    [types.CREATE_PROJECTS](state, payload) {
        state.projects = payload;
    },
    [types.CREATE_PROJECT](state, payload) {
        state.projects.push(payload);
    },
    [types.UPDATE_PROJECT](state, payload) {
        state.projects = state.projects.map(project => project.id === payload.id ? payload : project);
    },
    [types.DELETE_PROJECT](state, payload) {
        state.projects = state.projects.filter(project => project.id !== payload.id);
    },
    [types.CLEAR_PROJECTS](state) {
        state.projects = [];
    },
    [types.SELECT_RADIO_CARD](state, payload) {
        const project = state.projects.find(project => project.id === payload.projectId);
        if (project) {
            const category = project.categories.find(category => category.id === payload.categoryId);
            if (category) {
                const field = category.calculation.fields.find(field => field.id === payload.fieldId);
                if (field) {
                    field.value = payload.optionId;
                }
            }
        }
    },
    [types.UPDATE_BOOL_CARD](state, payload) {
        const project = state.projects.find(project => project.id === payload.projectId);
        if (project) {
            const category = project.categories.find(category => category.id === payload.categoryId);
            if (category) {
                const field = category.calculation.fields.find(field => field.id === payload.fieldId);
                if (field) {
                    const card = field.cards.find(card => card.id === payload.cardId);
                    if (card) {
                        card.value = payload.value;
                    }
                }
            }
        }
    },
    [types.UPDATE_NUMBER_CARD](state, payload) {
        const project = state.projects.find(project => project.id === payload.projectId);
        if (project) {
            const category = project.categories.find(category => category.id === payload.categoryId);
            if (category) {
                const field = category.calculation.fields.find(field => field.id === payload.fieldId);
                if (field) {
                    const card = field.cards.find(card => card.id === payload.cardId);
                    if (card) {
                        card.value = payload.value;
                    }
                }
            }
        }
    },
    [types.UPDATE_NUMBER_CARD_DOUBLE](state, payload) {
        const project = state.projects.find(project => project.id === payload.projectId);
        if (project) {
            const category = project.categories.find(category => category.id === payload.categoryId);
            if (category) {
                const field = category.calculation.fields.find(field => field.id === payload.fieldId);
                if (field) {
                    const card = field.cards.find(card => card.id === payload.cardId);
                    if (card) {
                        const input = card.inputs.find(input => input.id === payload.inputId);
                        if (input) {
                            input.value = payload.value;
                        }
                    }
                }
            }
        }
    }
}