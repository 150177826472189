import {mutations} from "./mutations";
import {actions} from "./actions";
import {getters} from "./getters";

const state = {
    loadingEvents: false,
    loadingEvent: false,
    errorEvents: null,
    events: []
}

const eventsModule = {
    state,
    mutations,
    actions,
    getters
}

export default eventsModule;