<template>
  <div>
    Hier erscheint Ihre Allgemeine Geschäftsbedingungen (AGB)
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "LegalConditions",
  computed: {
    ...mapGetters([
      'company'
    ])
  }
}
</script>