export const UPDATE_LOADING_PROJECTS = 'UPDATE_LOADING_PROJECTS';
export const UPDATE_ERROR_PROJECTS = 'UPDATE_ERROR_PROJECTS';
export const CREATE_PROJECTS = 'CREATE_PROJECTS';
export const CREATE_PROJECT = 'CREATE_PROJECT';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const DELETE_PROJECT = 'DELETE_PROJECT';
export const CLEAR_PROJECTS = 'CLEAR_PROJECTS';
export const SELECT_RADIO_CARD = 'SELECT_RADIO_CARD';
export const UPDATE_BOOL_CARD = 'UPDATE_BOOL_CARD';
export const UPDATE_NUMBER_CARD = 'UPDATE_NUMBER_CARD';
export const UPDATE_NUMBER_CARD_DOUBLE = 'UPDATE_NUMBER_CARD_DOUBLE';