import * as types from "./mutation-types";

export const mutations = {
    [types.UPDATE_LOADING_COMPANY](state, payload) {
        state.loadingCompany = payload;
    },
    [types.UPDATE_ERROR_COMPANY](state, payload) {
        state.errorCompany = payload;
    },
    [types.UPDATE_COMPANY](state, payload) {
        state.company = payload;
    }
}