import * as types from "./mutation-types";

export const mutations = {
    [types.UPDATE_LOADING_EVENTS](state, payload) {
        state.loadingEvents = payload;
    },
    [types.UPDATE_LOADING_EVENT](state, payload) {
        state.loadingEvent = payload;
    },
    [types.UPDATE_ERROR_EVENTS](state, payload) {
        state.errorEvents = payload;
    },
    [types.CREATE_EVENTS](state, payload) {
        state.events = payload;
    },
    [types.CREATE_EVENT](state, payload) {
        state.events.push(payload);
    },
    [types.UPDATE_EVENT](state, payload) {
        state.events = state.events.map(event => event.id === payload.id ? payload : event);

    }
}