<template>
  <div>
    <v-dialog v-model="showModalLogin" max-width="560">
      <v-card id="modalLogin" style="background-color: white;">
        <a class="modal-close d-inline-flex" @click="setState(false)">
          Schließen
          <i class="fa-solid fa-xmark"/>
        </a>
        <v-card-text class="pt-10 pb-4 d-flex flex-column">
          <h1 class="mb-5 text-center">Anmelden</h1>
          <v-alert
              v-if="errorLogin"
              class="mb-5"
              density="compact"
              type="error"
              variant="tonal"
          >
            {{ errorLogin }}
          </v-alert>
          <v-form
              v-model="loginForm.valid"
              :disabled="loadingLogin"
              class="w-100"
              lazy-validation
              @submit.prevent.stop="loginUser"
          >
            <v-row no-gutters>
              <v-col class="p-0" cols="12">
                <v-text-field
                    v-model="loginForm.email"
                    :rules="[rules.required, rules.email]"
                    autocomplete="email"
                    color="#0099ff"
                    density="comfortable"
                    label="E-Mail"
                    type="email"
                    variant="outlined"
                    clearable
                >
                </v-text-field>
              </v-col>
              <v-col class="p-0 password position-relative" cols="12">
                <v-text-field
                    v-model="loginForm.password"
                    :rules="[rules.required]"
                    :type="showPassword ? 'text' : 'password'"
                    autocomplete="current-password"
                    color="#0099ff"
                    density="comfortable"
                    label="Passwort"
                    variant="outlined"
                />
                <div
                    class="show-password position-absolute"
                    @mousedown="showPassword = true"
                    @mouseleave="showPassword = false"
                    @mouseup="showPassword = false"
                >
                  <span v-show="showPassword">
                    <i class="fa-solid fa-eye"/>
                  </span>
                  <span v-show="!showPassword">
                    <i class="fa-solid fa-eye-slash"/>
                  </span>
                </div>
              </v-col>
              <v-col class="p-0 d-flex justify-end align-center" cols="12">
                <v-btn
                    block
                    :disabled="loadingLogin"
                    :loading="loadingLogin"
                    class="btn-primary text-uppercase"
                    type="submit"
                >
                  <i class="fa-solid fa-user-check mr-2"/>Anmelden
                </v-btn>
              </v-col>
              <v-col class="mt-5 d-flex justify-start align-center" cols="12">
                <label class="d-flex align-center">
                  <a class="forgot-password" href="" @click.stop.prevent="showModalResetPassword">Passwort
                    vergessen?</a>
                </label>
              </v-col>
            </v-row>
          </v-form>
          <v-divider class="my-6 w-100"/>
          <h2 class="mb-5">Neu hier?</h2>
          <div class="text-left">
            <v-btn :disabled="loadingLogin" class="btn-primary mb-5" @click="showModalRegister">
              <i class="fa-solid fa-user-plus mr-2"/>Registrieren
            </v-btn>
          </div>
          <p class="agreement text-left text-subtitle-2">
            Mit der Erstellung eines Accounts stimmen Sie unseren <a href="" @click.stop.prevent="showLegalConditions">AGB's</a>
            und der <a
              href="" @click.stop.prevent="showLegalPrivacy">Datenschutzerklärung</a> zu.
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>
    <ModalRegister ref="ModalRegister"/>
    <ModalResetPassword ref="ModalResetPassword"/>
    <Legal ref="Legal"/>
  </div>
</template>

<script>
import ModalRegister from "@/components/modal/Register";
import ModalResetPassword from "@/components/modal/ResetPassword";
import Legal from "@/components/modal/Legal";
import {mapGetters} from "vuex";

export default {
  name: 'ModalLogin',
  components: {
    ModalRegister,
    ModalResetPassword,
    Legal
  },
  computed: {
    ...mapGetters([
      'loadingLogin',
      'errorLogin'
    ])
  },
  data() {
    return {
      showModalLogin: false,
      loginForm: {
        valid: false,
        email: '',
        password: ''
      },
      showPassword: false,
      rules: {
        required: value => !!value || 'Pflichtfeld.',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Die E-Mail ist ungültig.'
        }
      }
    }
  },
  methods: {
    setState(state) {
      this.showModalLogin = state;
    },
    showModalRegister() {
      this.setState(false);
      this.$refs.ModalRegister.setState(true);
    },
    showModalResetPassword() {
      this.setState(false);
      this.$refs.ModalResetPassword.setState(true);
    },
    showLegalConditions() {
      this.$refs.Legal.show({
        title: 'Nutzungsbedingungen',
        component: 'conditions'
      });
    },
    showLegalPrivacy() {
      this.$refs.Legal.show({
        title: 'Datenschutzerklärung',
        component: 'privacy'
      });
    },
    loginUser() {
      if (this.loginForm.valid) {
        this.$store.dispatch('login', this.loginForm);
      }
    }
  },
  watch: {
    showModalLogin(newValue, oldValue) {
      if (!newValue && oldValue) {
        this.loginForm = {
          valid: false,
          email: '',
          password: ''
        };
        this.$store.dispatch('resetErrorLogin');
      }
    },
    loadingLogin(newValue, oldValue) {
      if (this.showModalLogin && !newValue && oldValue && !this.errorLogin) {
        this.setState(false);
        this.$store.dispatch('getProjects');
        this.$router.push('/projects');
      }
    }
  },
  mounted() {
    if (this.$route.query.resetPasswordToken) {
      this.$refs.ModalResetPassword.setState(true);
    }
  }
}
</script>