import * as types from "./mutation-types";
import axios from "axios";

export const actions = {
    getProjects({commit, rootGetters}) {
        const user = rootGetters.user;
        if (user) {
            commit([types.UPDATE_ERROR_PROJECTS], false);
            commit(types.UPDATE_LOADING_PROJECTS, true);
            axios.post(`/api/projects`, {userId: user.id}, {
                headers: {
                    "x-auth-token": rootGetters.jwt
                }
            })
                .then(response => {
                    if (response.data.ok) {
                        commit(types.CREATE_PROJECTS, response.data.projects);
                    } else {
                        commit([types.UPDATE_ERROR_PROJECTS], response.data.error);
                    }
                    commit(types.UPDATE_LOADING_PROJECTS, false);
                })
                .catch(() => commit(types.UPDATE_LOADING_PROJECTS, false));
        }
    },
    saveProject({commit, rootGetters}, payload) {
        commit([types.UPDATE_ERROR_PROJECTS], false);
        commit(types.UPDATE_LOADING_PROJECTS, true);
        if (!payload.id && payload.name && payload.configuration) {
            //create project
            axios.post(`/api/project`,
                {
                    userId: rootGetters.user.id,
                    name: payload.name,
                    categories: payload.configuration
                },
                {
                    headers: {
                        "x-auth-token": rootGetters.jwt
                    }
                })
                .then(response => {
                    if (response.data.ok) {
                        commit(types.CREATE_PROJECT, response.data.project);
                    } else {
                        commit([types.UPDATE_ERROR_PROJECTS], response.data.error);
                    }
                    commit(types.UPDATE_LOADING_PROJECTS, false);
                })
                .catch(() => commit(types.UPDATE_LOADING_PROJECTS, false));
        } else {
            //update project
            axios.put(`/api/project/${payload.id}`,
                {
                    categories: payload.configuration || null,
                    orderStreet: payload.orderStreet || null,
                    orderZip: payload.orderZip || null,
                    orderPlace: payload.orderPlace || null,
                    orderHouse: payload.orderHouse || null
                },
                {
                    headers: {
                        "x-auth-token": rootGetters.jwt
                    }
                })
                .then(response => {
                    if (response.data.ok) {
                        commit(types.UPDATE_PROJECT, response.data.project);
                    } else {
                        commit([types.UPDATE_ERROR_PROJECTS], response.data.error);
                    }
                    commit(types.UPDATE_LOADING_PROJECTS, false);
                })
                .catch(() => commit(types.UPDATE_LOADING_PROJECTS, false));
        }
    },
    renameProject({commit, rootGetters}, payload) {
        commit([types.UPDATE_ERROR_PROJECTS], false);
        commit(types.UPDATE_LOADING_PROJECTS, true);
        axios.put(`/api/project/${payload.id}`,
            {
                name: payload.name
            },
            {
                headers: {
                    "x-auth-token": rootGetters.jwt
                }
            })
            .then(response => {
                if (response.data.ok) {
                    commit(types.UPDATE_PROJECT, response.data.project);
                } else {
                    commit([types.UPDATE_ERROR_PROJECTS], response.data.error);
                }
                commit(types.UPDATE_LOADING_PROJECTS, false);
            })
            .catch(() => commit(types.UPDATE_LOADING_PROJECTS, false));
    },
    deleteProject({commit, rootGetters}, payload) {
        commit([types.UPDATE_ERROR_PROJECTS], false);
        commit(types.UPDATE_LOADING_PROJECTS, true);
        axios.delete(`/api/project/${payload.id}`, {
            headers: {
                "x-auth-token": rootGetters.jwt
            }
        })
            .then(response => {
                if (response.data.ok) {
                    commit(types.DELETE_PROJECT, response.data.project);
                } else {
                    commit([types.UPDATE_ERROR_PROJECTS], response.data.error);
                }
                commit(types.UPDATE_LOADING_PROJECTS, false);
            })
            .catch(() => commit(types.UPDATE_LOADING_PROJECTS, false));
    },
    clearProjects({commit}) {
        commit([types.UPDATE_ERROR_PROJECTS], false);
        commit(types.UPDATE_LOADING_PROJECTS, true);
        commit(types.CLEAR_PROJECTS);
        commit(types.UPDATE_LOADING_PROJECTS, false);
    },
    selectRadioCard({commit}, payload) {
        commit(types.SELECT_RADIO_CARD, payload);
    },
    updateBoolCard({commit}, payload) {
        commit(types.UPDATE_BOOL_CARD, payload);
    },
    updateNumberCard({commit}, payload) {
        commit(types.UPDATE_NUMBER_CARD, payload);
    },
    updateNumberCardDouble({commit}, payload) {
        commit(types.UPDATE_NUMBER_CARD_DOUBLE, payload);
    }
}