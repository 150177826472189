import axios from "axios";

const state = {
    loadingContact: false,
    errorContact: false
}

const mutations = {
    UPDATE_LOADING_CONTACT(state, payload) {
        state.loadingContact = payload;
    },
    UPDATE_ERROR_CONTACT(state, payload) {
        state.errorContact = payload;
    }
}

const actions = {
    sendMail({commit}, payload) {
        commit(['UPDATE_ERROR_CONTACT'], false);
        commit(['UPDATE_LOADING_CONTACT'], true);
        return axios.post(`/api/mail`, payload)
            .then(response => {
                if (!response.data.ok) {
                    commit(['UPDATE_ERROR_CONTACT'], response.data.error);
                }
                commit(['UPDATE_LOADING_CONTACT'], false);
                return response.data.ok;
            })
            .catch(() => commit(['UPDATE_LOADING_CONTACT'], false));
    }
}

const getters = {
    loadingContact: state => state.loadingContact,
    errorContact: state => state.errorContact
}

const contactModule = {
    state,
    mutations,
    actions,
    getters
}

export default contactModule;