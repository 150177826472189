<template>
  <v-card class="mb-10 mt-3 mx-3 pa-4" style="background-color: white;">
    <v-form
        ref="Form"
        v-model="valid"
        class="w-100"
        lazy-validation
        @submit.prevent.stop="save"
    >
      <v-row>
        <v-col cols="12" md="4" class="pb-0">
          <v-text-field
              v-model="form.currentPassword"
              :rules="[rules.required]"
              autocomplete="on"
              color="#0099ff"
              density="comfortable"
              label="Aktuelles Passwort"
              type="password"
              variant="outlined"
          />
        </v-col>
        <v-col cols="12" md="4" class="pb-0">
          <v-text-field
              v-model="form.newPassword"
              :rules="[rules.required, rules.password, rules.passwordMin, rules.passwordMax]"
              autocomplete="on"
              color="#0099ff"
              density="comfortable"
              label="Neues Passwort"
              type="password"
              variant="outlined"
          />
        </v-col>
        <v-col cols="12" md="4" class="pb-0">
          <v-text-field
              v-model="form.confirmNewPassword"
              :rules="[rules.required, rules.confirm]"
              autocomplete="on"
              color="#0099ff"
              density="comfortable"
              label="Neues Passwort bestätigen"
              type="password"
              variant="outlined"
          />
        </v-col>

        <v-col class="p-0 d-flex justify-start align-center">
          <label class="d-flex align-center">
            <a class="forgot-password" href="" @click.stop.prevent="showModalResetPassword">
              Passwort vergessen?
            </a>
          </label>
        </v-col>


        <v-col class="d-flex justify-end" cols="12">
          <v-btn
              :disabled="loadingUser"
              :loading="loadingUser"
              block
              class="btn-primary"
              type="submit">
            <i class="fa-regular fa-floppy-disk mr-2"/>Speichern
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
  <v-col>
    <v-btn
        :disabled="loadingUser"
        :loading="loadingUser"
        block
        class="mb-2 btn-delete"
        @click="confirmDeleteUser">
      <i class="fa-solid fa-user-xmark me-2"/>Account löschen
    </v-btn>
  </v-col>
  <ModalDeleteUser ref="ModalDeleteUser"/>
  <ModalResetPassword ref="ModalResetPassword"/>
  <Toast ref="Toast"/>
</template>

<script>
import ModalDeleteUser from "@/components/modal/DeleteUser";
import ModalResetPassword from "@/components/modal/ResetPassword";
import Toast from '@/components/modal/Toast';
import {mapGetters} from "vuex";

export default {
  name: "AccountSecurity",
  components: {
    ModalDeleteUser,
    ModalResetPassword,
    Toast
  },
  data() {
    return {
      valid: false,
      form: {
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: ''
      },
      rules: {
        required: value => !!value || 'Pflichtfeld.',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Die E-Mail ist ungültig.'
        },
        password: value => {
          const pattern = /^(?=.*[a-z])(?=.*[A-Za-z])(?=.*[0-9])(?=.*[~!@#$%^&*\-_+=|";[\]{}()<>,./?\\])/;
          return pattern.test(value) || 'Das Passwort muss Buchstaben, Ziffern und Sonderzeichen(~!@#$%^&*-_+=|";[]{}()<>,./?\\) enthalten.';
        },
        passwordMin: value => value.length >= 9 || 'Das Passwort muss mindestens 9 Zeichen lang sein.',
        passwordMax: value => value.length <= 20 || 'Das Passwort darf maximal 20 Zeichen lang sein.',
        confirm: value => (value === this.form.newPassword) && this.form.newPassword !== '' || 'Die Passwörter müssen übereinstimmen.'
      }
    }
  },
  computed: {
    ...mapGetters([
      'loadingUser',
      'errorUser',
      'user'
    ])
  },
  methods: {
    save() {
      if (this.$refs.Form.validate() && this.valid) {
        this.$store.dispatch('updateUser', {
          currentPassword: this.form.currentPassword,
          newPassword: this.form.newPassword
        }).then(response => {
          if (response) {
            this.$refs.Toast.show({
              color: 'success',
              icon: 'fa-solid fa-check',
              title: 'Passwort aktualisiert',
              text: 'Ihr Passwort wurde erfolgreich geändert.'
            });
          }
        });
      }
    },
    confirmDeleteUser() {
      this.$refs.ModalDeleteUser.show();
    },
    showModalResetPassword() {
      this.$refs.ModalResetPassword.setState(true);
    },

  }
}
</script>