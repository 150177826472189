import {createStore} from 'vuex'
import userModule from "./modules/user";
import projectsModule from "./modules/projects";
import categoriesModule from "./modules/categories";
import eventsModule from "./modules/events";
import companyModule from "./modules/company";
import contactModule from "./modules/contact";

export default createStore({
    strict: true,
    modules: {
        userModule,
        projectsModule,
        categoriesModule,
        eventsModule,
        companyModule,
        contactModule
    }
})
