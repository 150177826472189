<template>
  <div v-if="loadingUser" class="my-16 py-16 d-flex justify-center">
    <v-progress-circular color="#0099ff" indeterminate/>
  </div>
  <div v-else class="pb-10">
    <v-card class="mb-5 mt-3 mx-3 pa-4" style="background-color: white;">
      <v-form
          class="w-100"
          @submit.prevent.stop="save"
      >
        <v-row>
          <v-col class="mb-3" cols="12" md="2">
            <v-select
                v-model="form.customer"
                :items="['Privat','Gewerblich']"
                autocomplete="on"
                color="#0099ff"
                density="comfortable"
                hide-details
                label="Kunde"
                type="text"
                variant="outlined"
            />
          </v-col>
          <v-col v-if="form.customer !== 'Gewerblich'" cols="0" md="10"/>
          <v-col v-if="form.customer === 'Gewerblich'" class="mb-3" cols="12" md="12">
            <v-text-field
                v-model="form.company"
                autocomplete="on"
                color="#0099ff"
                density="comfortable"
                hide-details
                clearable
                label="Firma"
                type="text"
                variant="outlined"
            />
          </v-col>
          <v-col cols="12" class="pa-0">
            <v-col class="mb-3" cols="12" md="2">
              <v-select
                  v-model="form.title"
                  :items="['Frau', 'Herr', 'Divers']"
                  autocomplete="on"
                  color="#0099ff"
                  density="comfortable"
                  hide-details
                  clearable
                  label="Anrede"
                  type="text"
                  variant="outlined"
              />
            </v-col>
          </v-col>
          <v-col class="mb-3" cols="12" md="6">
            <v-text-field
                v-model="form.firstname"
                autocomplete="on"
                color="#0099ff"
                density="comfortable"
                hide-details
                clearable
                label="Vorname"
                type="text"
                variant="outlined"
            />
          </v-col>
          <v-col class="mb-3" cols="12" md="6">
            <v-text-field
                v-model="form.lastname"
                autocomplete="on"
                color="#0099ff"
                density="comfortable"
                hide-details
                clearable
                label="Nachname"
                type="text"
                variant="outlined"
            />
          </v-col>
          <v-col class="mb-3" cols="12" md="9">
            <v-text-field
                v-model="form.street"
                autocomplete="on"
                color="#0099ff"
                density="comfortable"
                hide-details
                clearable
                label="Straße"
                type="text"
                variant="outlined"
            />
          </v-col>
          <v-col class="mb-3" cols="12" md="3">
            <v-text-field
                v-model="form.house"
                autocomplete="on"
                color="#0099ff"
                density="comfortable"
                hide-details
                clearable
                label="Hausnummer"
                type="text"
                variant="outlined"
            />
          </v-col>
          <v-col class="mb-3" cols="12" sm="4">
            <v-text-field
                v-model="form.zip"
                autocomplete="on"
                color="#0099ff"
                density="comfortable"
                hide-details
                clearable
                label="PLZ"
                type="text"
                variant="outlined"
            />
          </v-col>
          <v-col class="mb-3" cols="12" sm="8">
            <v-text-field
                v-model="form.place"
                autocomplete="on"
                color="#0099ff"
                density="comfortable"
                hide-details
                clearable
                label="Ort"
                type="text"
                variant="outlined"
            />
          </v-col>
          <v-col class="mb-3" cols="12" sm="6">
            <v-text-field
                v-model="form.phone"
                autocomplete="on"
                color="#0099ff"
                density="comfortable"
                hide-details
                clearable
                label="Telefon"
                type="text"
                variant="outlined"
            />
          </v-col>
          <v-col class="mb-3" cols="12" sm="6">
            <v-text-field
                v-model="form.email"
                :rules="[rules.email]"
                autocomplete="on"
                color="#0099ff"
                density="comfortable"
                hide-details
                readonly
                label="E-Mail"
                type="text"
                variant="outlined"
            />
          </v-col>
          <v-col class="d-flex justify-end" cols="12">
            <v-btn
                block
                class="btn-primary animation"
                type="submit">
              <i class="fa-regular fa-floppy-disk mr-2"/>
              Speichern
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "AccountDetails",
  data() {
    return {
      form: {
        customer: '',
        title: '',
        company: '',
        firstname: '',
        lastname: '',
        street: '',
        house: '',
        zip: '',
        place: '',
        phone: '',
        email: ''
      },
      rules: {
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Die E-Mail ist ungültig.'
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'loadingUser',
      'user'
    ])
  },
  methods: {
    updateFields() {
      this.form.customer = this.user.customer;
      this.form.company = this.user.company;
      this.form.title = this.user.title;
      this.form.firstname = this.user.firstname;
      this.form.lastname = this.user.lastname;
      this.form.street = this.user.street;
      this.form.house = this.user.house;
      this.form.zip = this.user.zip;
      this.form.place = this.user.place;
      this.form.phone = this.user.phone;
      this.form.email = this.user.email;
    },
    save() {
      this.$store.dispatch('updateUser', {
        customer: this.form.customer,
        company: this.form.company,
        title: this.form.title,
        firstname: this.form.firstname,
        lastname: this.form.lastname,
        street: this.form.street,
        house: this.form.house,
        zip: this.form.zip,
        place: this.form.place,
        phone: this.form.phone,
        email: this.form.email
      });
    }
  },
  created() {
    this.updateFields();
  }
}
</script>