import {mutations} from "./mutations";
import {actions} from "./actions";
import {getters} from "./getters";

const state = {
    categories: []
}

const categoriesModule = {
    state,
    mutations,
    actions,
    getters
}

export default categoriesModule;