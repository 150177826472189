<template>
  <div>
    <v-dialog
        v-model="active"
        :max-width="maxWidth"
        :persistent="persistent"
    >
      <v-card style="background-color: white;">
        <v-card-title class="text-h5 text-center">Account löschen</v-card-title>
        <v-card-text class="pt-10">
          <p class="mb-3 text-center">Möchten Sie Ihr Benutzerkonto wirklich löschen? Eine Wiederherstellung Ihrer Daten wird nicht mehr möglich sein.</p>
          <p class="mb-5 text-center">Bitte geben Sie hierzu Ihr Passwort ein.</p>
          <v-alert
              v-if="passwordError"
              class="mb-5"
              density="compact"
              type="error"
              variant="tonal"
          >
            Bitte geben Sie Ihr Passwort ein.
          </v-alert>
          <v-alert
              v-else-if="errorUserDelete"
              class="mb-5"
              density="compact"
              type="error"
              variant="tonal"
          >
            {{ errorUserDelete }}
          </v-alert>
          <v-text-field
              v-model="password"
              :rules="[rules.required]"
              autocomplete="current-password"
              color="#0099ff"
              density="comfortable"
              label="Passwort"
              type="password"
              variant="outlined"
              hide-details
              class="mb-10"
          />
          <v-row no-gutters>
            <v-col cols="6" class="pe-2">
              <v-btn
                  :color="cancelColor"
                  depressed
                  @click.stop.prevent="submit(false)"
                  class="btn-primary"
                  block
              >
                {{ cancelTitle }}
              </v-btn>
            </v-col>
            <v-col cols="6" class="ps-2">
              <v-btn
                  :color="confirmColor"
                  depressed
                  @click.stop.prevent="submit(true)"
                  class="btn-delete"
                  block
              >
                {{ confirmTitle }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: 'ModalConfirm',
  data() {
    return {
      active: false,
      persistent: false,
      maxWidth: 560,
      cancelColor: '',
      cancelTitle: 'Nein',
      confirmColor: 'error',
      confirmTitle: 'Ja',
      password: '',
      passwordError: false,
      rules: {
        required: value => !!value || 'Pflichtfeld.'
      }
    }
  },
  computed: {
    ...mapGetters([
        'errorUserDelete'
    ])
  },
  methods: {
    show(options = {}) {
      if (this.active) {
        this.close();
        this.$nextTick(() => this.show(options));
        return;
      }
      Object.keys(options).forEach(field => this[field] = options[field]);
      this.passwordError = false;
      this.active = true;
    },
    submit(value) {
      if (value) {
        this.passwordError = false;
        if (this.password) {
          this.$store.dispatch('deleteUser', {
            password: this.password
          }).then(response => {
            if (response) {
              this.active = false;
              this.$router.push('/');
            }
          });
        } else {
          this.passwordError = true;
        }
      } else {
        this.active = false;
      }
    }
  }
}
</script>