<template>
  <div id="menu-faq">
    <v-expansion-panels variant="accordion">
      <v-expansion-panel
          v-for="i in 1"
          :key="i"
          title="Konto anlegen oder als Gast"
      >
        <v-expansion-panel-text>
          Als angemeldeter Benutzer (geeignet für Stammkunden) können Sie Ihre Angaben bei den ..Kategorien, ..Elemente
          & ggf. ..Reinigungsort in Ihrem persönlichen Benutzerkonto als Projekt Speichern.<br>
          Dies dient dazu, dieselben Angaben (bei der nächsten Auftragsanfrage) nicht erneut angegeben werden
          müssen.<br>
          Als Gast werden Ihre Angaben nicht gespeichert, jedoch haben Sie trotzdem die Möglichkeit, Aufträge zu
          versenden.
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel
          v-for="i in 1"
          :key="i"
          title="Projekt erstellen und abspeichern"
      >
        <v-expansion-panel-text>
          <b>1.</b> Anmelden/Registrieren.<br>
          <b>2.</b> Klicken Sie auf der
          <button style="color: #0089ff; text-decoration: underline;" @click="$router.push('/projects')">Projektseite</button>
          auf den Button [+Hinzufügen].<br>
          <b>3.</b> Wählen Sie Ihre Kategorien & Elemente.<br>
          <b>4.</b> Klicken Sie auf Speichern und geben Sie Ihren gewünschten Projektnamen ein.
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel
          v-for="i in 1"
          :key="i"
          title="Wann erhalte ich eine Terminbestätigung ?"
      >
        <v-expansion-panel-text>
          Werktags innerhalb von 48 Std.
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel
          v-for="i in 1"
          :key="i"
          title="Keine Terminbestätigung erhalten ?"
      >
        <v-expansion-panel-text>
          Bitte kontaktieren Sie uns unter der Tel.Nr: {{ company.phone }} es könnte evtl. ein technischer Fehler vorliegen.
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel
          v-for="i in 1"
          :key="i"
          title="Nach dem versenden der Terminanfrage."
      >
        <v-expansion-panel-text>
          <b>1.</b> Nach dem Versenden der Terminanfrage erhalten Sie nach wenigen Minuten eine automatisch generierte
          E-Mail zur Übersicht Ihrer Angaben.<br>
          Info > Sollten Sie innerhalb von 5 min keine E-Mail bekommen haben, überprüfen Sie Ihre Internetverbindung und
          senden Sie die Auftragsanfrage erneut.<br>
          <b>2.</b> Wir überprüfen Ihre Angaben und melden uns innerhalb von 48 Std. bei Ihnen.
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "FAQ",
  computed: {
    ...mapGetters([
      'company'
    ])
  }
}
</script>