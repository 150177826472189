<template>
  <v-snackbar
      v-model="active"
      :color="color"
      :timeout="timeout"
      class="toast"
      location="center center"
      multi-line
      position="fixed"
  >
    <i :class="icon" class="toast-icon"/>
    <div class="px-10 d-flex flex-column">
      <span v-if="title" class="mb-1 text-h6 toast-title">{{ title }}</span>
      {{ text }}
    </div>
    <template v-slot:actions>
      <v-btn
          icon
          @click="close"
      >
        <i class="fa-solid fa-xmark"/>
      </v-btn>
    </template>
  </v-snackbar>
</template>


<script>
export default {
  name: 'ModalToast',
  data() {
    return {
      active: false,
      timeout: -1,
      color: 'info',
      icon: 'fa-solid fa-circle-info',
      title: '',
      text: ''
    };
  },
  methods: {
    show(options = {}) {
      if (this.active) {
        this.close();
        this.$nextTick(() => this.show(options));
        return;
      }
      Object.keys(options).forEach(field => (this[field] = options[field]));
      this.active = true;
    },
    close() {
      this.active = false;
    }
  },
};
</script>