<template>
  <div>
    <v-dialog
        v-model="showModalLegal"
        max-width="560"
    >
      <v-card id="modalLegal" style="background-color: white;">
        <a class="modal-close d-inline-flex"
           @click="showModalLegal = false"
        >
          Schließen
          <i class="fa-solid fa-xmark"/>
        </a>
        <v-card-text class="pt-10">
          <h1 class="mb-2 text-center">{{ title }}</h1>
          <component :is="getComponent"/>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import FAQ from "@/components/modal/Web-App/FAQ";
import Imprint from "@/components/legal/Imprint";
import Privacy from "@/components/legal/Privacy";
import Conditions from "@/components/legal/Conditions";

export default {
  name: 'ModalLegal',
  data() {
    return {
      showModalLegal: false,
      title: '',
      component: null
    }
  },
  computed: {
    getComponent() {
      if (this.component === 'faq') return FAQ;
      if (this.component === 'imprint') return Imprint;
      if (this.component === 'privacy') return Privacy;
      if (this.component === 'conditions') return Conditions;
      return null;
    }
  },
  methods: {
    show(options = {}) {
      if (this.showModalLegal) {
        this.close();
        this.$nextTick(() => this.show(options));
        return;
      }
      Object.keys(options).forEach(field => (this[field] = options[field]));
      this.showModalLegal = true;
    }
  }
}
</script>